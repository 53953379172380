import axios from "axios";
import qs from "qs";

const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    "Accept": "*/*",
    "Access-Control-Allow-Origin": "*",
};

const axiosInst = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: headers,
    responseType: "blob",
    transformRequest: [
        (data, headers) => {
            if (!(data instanceof FormData)) {
                var queryString = qs.stringify(data, { arrayFormat: "brackets" });
                data = queryString;
            }
            return data;
        },
        ...axios.defaults.transformRequest,
    ],
});

// axiosWrpr interceptor 설정

axiosInst.interceptors.request.use(
    function (config) {
        // 1. 요청 보내기 전에 실행
        // store의 토큰 값 설정
        let token = localStorage.getItem("authorization");
        if (token != null && token != "") {
            config.headers.Authorization = localStorage.getItem("authorization");
        }
        return config;
    },
    function (error) {
        console.error("interceptors axiosWrpr Failed:", error.config);
        // Do something with axiosWrpr error
        return Promise.reject(error);
    }
);

axiosInst.interceptors.response.use(
    function (response) {
        if (
            response.headers["authorization"] != null &&
            response.headers["authorization"] != ""
        ) {
            localStorage.setItem("authorization", response.headers["authorization"]);
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// axiosWrpr Wrapper with default success/error actions
const axiosWrpr = function (options) {
    //SuccessHandler
    const onSuccess = function (response) {
        // 전체 모달창 닫기
        try {
            //hideAllModel();
        } catch (e) { }

        return response;
    };

    //ErrorHandler
    const onError = function (error) {
        console.log("axiosWrpr Failed:", error.config);

        if (error.response) {
        } else {
        }

        //return Promise.reject(error.response || error.message);
        return error;
    };

    return axiosInst(options).then(onSuccess).catch(onError);
};

export default axiosWrpr;
