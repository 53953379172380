export default {
    namespaced: true,
    state: {
        main : {
            currentTab : 'm-tab-1'
        },
        cmmnt : {
            postNum : null,
            keyword : '',
            title : '',
            cntn : '',
            tag : '',
            postCtgryCode : '000',
            orderCode : '001',
            activePostNum : null,
        },
        srch : {
            keyword : '',
            postCtgryCode : '000',
            orderCode : '001',
        },
        filter : {
            keyword : '',
            postCtgryCode : '001',
            orderCode : '001',
        },
        cmnt : {
            activeCmntNum : null,
            lst : '',
        }
    },
    getters: {
        selCmnt: (state) => (payload) => {
            let cmntView = null;
            if (payload.upprCmntNum) {
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.cmntNum === payload.upprCmntNum);
                if (parentComment) {
                    cmntView = parentComment.childCmntView.find(cmnt => cmnt.cmntNum === payload.cmntNum);
                }
            } else {
                cmntView = state.cmnt.lst.find(cmnt => cmnt.cmntNum === payload.cmntNum);
            }
            return cmntView;
        }
    },
    mutations: {
        setCurrentTab(state, payload){
            state.main.currentTab = payload;
        },
        clearCmmnt(state){
            const clearCmmnt = {
                postNum : null,
                keyword : '',
                title : '',
                cntn : '',
                tag : '',
                postCtgryCode : '000',
                orderCode : '001',
                activePostNum : null,
            }
            state.cmmnt = clearCmmnt;
        },
        setCmmntFromModal(state, payload) {
            state[payload.stateName][payload.key] = payload.value;
        },
        setCmmntSrch(state, payload) {
            state.srch = payload;
        },
        setCmmntSrchValue(state, payload){
            state.srch[payload.key] = payload.value;
        },
        setCmmntFilterValue(state, payload){
            state.filter[payload.key] = payload.value;
        },
        setCmmntTag(state, payload) {
            state.cmmnt.tag = payload;
        },
        setCmmntFromWrite(state, payload) {
            state.cmmnt.postNum = payload.postNum;
            state.cmmnt.title = payload.title;
            state.cmmnt.cntn = payload.cntn;
            state.cmmnt.tag = payload.tag;
            state.cmmnt.postCtgryCode = payload.postCtgryCode;
        },
        setCmntLst(state, payload){
            state.cmnt.lst = payload;
        },
        delCmnt(state, payload){
            if (payload.upprCmntNum) {
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.cmntNum === payload.upprCmntNum);
                
                if (parentComment) {
                    const index = parentComment.childCmntView.findIndex(cmnt => cmnt.cmntNum === payload.cmntNum);
                    if (index !== -1) {
                      parentComment.childCmntView.splice(index, 1, payload);
                    }
                }
            } else {
                let cmntView = state.cmnt.lst.find(cmnt => cmnt.cmntNum === payload.cmntNum);

                if (cmntView){
                    cmntView.delYn = payload.delYn;
                    cmntView.cntn = payload.cntn;
                }

            }
        },
        setActivePostNum(state, payload) {
            state.cmmnt.activePostNum = payload;
        },
        clearActivePostNum(state) {
            state.cmmnt.activePostNum = null;
        },
        setActiveCmntNum(state, cmntNum) {
            state.cmnt.activeCmntNum = cmntNum;
        },
        clearActiveCmntNum(state) {
            state.cmnt.activeCmntNum = null;
        },
        clearCmntLst(state, payload){
            state.cmnt.lst = '';
        },
        pushCmnt(state, payload){
            // payload에 upprTripinfoCmntNum이 있는지 확인
            if (payload.upprCmntNum) {
                // lst 배열에서 tripinfoCmntNum이 upprTripinfoCmntNum과 일치하는 요소를 찾음
                const parentComment = state.cmnt.lst.find(cmnt => cmnt.cmntNum === payload.upprCmntNum);
                
                // 해당 요소를 찾았으면 그 요소의 childTripinfoLst 배열에 payload를 추가
                if (parentComment) {
                    if (!parentComment.childCmntView) {
                        // 만약 childTripinfoLst 배열이 존재하지 않는다면, 초기화해줌
                        parentComment.childCmntView = [];
                    }
                    parentComment.childCmntView.push(payload);
                }
            } else {
                // upprTripinfoCmntNum이 없는 경우, lst 배열에 payload를 직접 추가
                state.cmnt.lst.push(payload);
            }
        },
    },
    actions: {
        setCurrentTab({ state, commit }, payload) {
            commit('setCurrentTab', payload);
        },
        setCmmntFromModal({ state, commit }, payload) {
            commit('setCmmntFromModal', payload);
        },
        clearCmmnt({ state, commit }) {
            commit('clearCmmnt');
        },
        setCmmntSrch({ state, commit }, payload) {
            commit('setCmmntSrch', payload);
        },
        setCmmntSrchValue({ state, commit }, payload) {
            commit('setCmmntSrchValue', payload);
        },
        setCmmntFilterValue({ state, commit }, payload) {
            commit('setCmmntFilterValue', payload);
        },
        setCmmntTag({ state, commit }, payload) {
            commit('setCmmntTag', payload);
        },
        setCmmntFromWrite({ state, commit }, payload) {
            commit('setCmmntFromWrite', payload);
        },
        setCmntLst({ state, commit }, payload) {
            commit('setCmntLst', payload);
        },
        clearCmntLst({ state, commit }, payload) {
            commit('clearCmntLst', payload);
        },
        pushCmnt({ state, commit }, payload) {
            commit('pushCmnt', payload);
        },
        delCmnt({ state, commit }, payload) {
            commit('delCmnt', payload);
        },
        setActivePostNum({ commit }, payload) {
            commit('setActivePostNum', payload);
        },
        clearActivePostNum({ commit }) {
            commit('clearActivePostNum');
        },
        setActiveCmntNum({ commit }, cmntNum) {
            commit('setActiveCmntNum', cmntNum);
        },
        clearActiveCmntNum({ commit }) {
            commit('clearActiveCmntNum');
        }
    },
};
