export default {
    namespaced: true,
    state: {
        comm : {
            storeName : 'comm',
            show : false,
            title : "comm",
            code : "comm",
            mode : "ALERT", // ALERT / CONRFIME
            event : "comm",
        },
    },
    getters: {},
    mutations: {
        setAlert(state, payload) {
            state.comm = payload;
        },
        clearAlert(state, payload) {
            const comm = {
                storeName : 'comm',
                show : false,
                title : "comm",
                code : "comm",
                mode : "ALERT",
                event : "comm",
            }

            state.comm = comm;
        },
    },
    actions: {
        setAlert({ state, commit }, payload) {
            commit('setAlert', payload);
        },
        clearAlert({ state, commit }, payload) {
            commit('clearAlert', payload);
        },
    },
};
