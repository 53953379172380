import cnst from '@/util/cnst.js'

export default {
    namespaced: true,
    state: {
        user: {
            loginGbnCode : cnst.SMPL.NORMAL.USER_REG_TYPE_CODE,
            lang : 'eng',
            bLoginChk : false,
            userNum : null,
            nick : null,
            gndr : "000",
            prflImgNum : "",
            birthYear : '2024',
            birthMonth : '03',
            birthDay : '04',
            prflFileFullPath : "",
        },
        tmpUser: {
            loginGbnCode : cnst.SMPL.NORMAL.USER_REG_TYPE_CODE,
            lang : 'eng',
            bLoginChk : false,
            userNum : null,
            nick : null,
            gndr : "000",
            prflImgNum : "",
            birthYear : '2024',
            birthMonth : '03',
            birthDay : '04',
            prflFileFullPath : "",
        },
    },
    getters: {},
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setTmpUser(state, payload) {
            state.tmpUser = payload;
        },
        setUserFromModal(state, payload) {
            state[payload.stateName][payload.key] = payload.value;
        },
        setLoginChk(state, payload) {
            state.user.bLoginChk = payload;
        },
        setLang(state, payload){
            state.user.lang = payload;
        }
    },
    actions: {
        setUser({ state, commit }, payload) {
            commit('setUser', payload);
        },
        setTmpUser({ state, commit }, payload) {
            commit('setTmpUser', payload);
        },
        setUserFromModal({ state, commit }, payload) {
            commit('setUserFromModal', payload);
        },
        setLoginChk({ state, commit }, payload) {
            commit('setLoginChk', payload);
        },
        setLang({ state, commit }, payload) {
            commit('setLang', payload);
        },
    },
};
