import { createStore } from "vuex";
import commModule from "./comm/comm.module.js";
import layerModule from "./comm/layer.module.js";
import alertModule from "@/store/comm/alert.module.js"
import locationModule from "./comm/location.module.js";
import userModule from "./user/user.module.js";
import tripinfoModule from "@/store/tripinfo/tripinfo.module.js"
import cmmntModule from "./cmmnt/cmmnt.module.js";

export default createStore({
  state: {
    test: "1222",
    serverUrl: process.env.VUE_APP_SERVER_URL,
  },
  getters: {
    getServerUrl: (state) => state.serverUrl,
  },
  mutations: {},
  actions: {},
  modules: {
    layer: layerModule,
    alert: alertModule,
    location: locationModule,
    user: userModule,
    comm: commModule,
    tripinfo: tripinfoModule,
    cmmnt: cmmntModule,
  },
});
