export default {
    namespaced: true,
    state: {
        comm : {
            storeName : 'comm',
            stateName : 'comm',
            funcName : 'comm',
            valueName : 'comm',
            langPath : '',
            show : false,
            lst : [],
        },
    },
    getters: {},
    mutations: {
        setLayerLst(state, payload) {
            state.comm = payload;
        },
    },
    actions: {
        setLayerLst({ state, commit }, payload) {
            commit('setLayerLst', payload);
        },
    },
};
