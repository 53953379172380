// USER 분류
var cnst = {
  USER: {
    GBN: {
      USER: "10",
      ADMN: "30",
    },
  },
  SMPL :{
    NORMAL : {
      SMPL_LOGIN_CODE : 'NORMAL',
    },
    NAVER : {
      SMPL_LOGIN_CODE : 'NAVER',
      CLIENT_ID : '5cIkkFdcVib0gTQkn2GO',
      CALLBACK_URL : 'login/naver',
    },
    KAKAO : {
      SMPL_LOGIN_CODE : 'KAKAO',
      CLIENT_ID : 'f2dbc669e2f438302a6eae56199a47df',
      CALLBACK_URL : 'login/kakao',
    },
    GOOGLE : {
      SMPL_LOGIN_CODE : 'GOOGLE',
      CLIENT_ID : '348971242191-8mtbnvn58ara978kju4pvf8cqglse0e3.apps.googleusercontent.com',
      CALLBACK_URL : 'login/google',
    },
    FACEBOOK : {
      SMPL_LOGIN_CODE : 'FACEBOOK',
      CLIENT_ID : '890145459265374',
      CALLBACK_URL : 'http://localhost:7001/login/facebook',
    }
  }
};

export default cnst;
