import { env } from "face-api.js";

export default {
    namespaced: true,
    state: {
        baseUrl : "",
        showSppiner : false,
    },
    getters: {},
    mutations: {
        setLocation(state, payload) {
            state.latlot.lat = payload.latitude;
            state.latlot.lot = payload.longitude;
        },
        setShowSppiner(state, payload){
            state.showSppiner = payload;
        }
    },
    actions: {
        setLocation({ state, commit }, payload) {
            commit('setLocation', payload);
        },
        setShowSppiner({ state, commit }, payload) {
            commit('setShowSppiner', payload);
        },
    },
};
